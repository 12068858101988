import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CardOptica from "../statics/CardOptic";

import { /*removeIdLead,*/ setIdLead } from "../../redux/actions/lead.action";
import { getOpticaByProximity } from "../../services/optic.services";
import { useMediaQuery } from "react-responsive";
import { Col, Row, Container, Button } from "reactstrap";
import StickyBox from "react-sticky-box";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

//Google Maps
import PutPostCode from "../statics/PutPostCode";
import { setOptica } from "../../redux/actions/optica.action";
import { setUser } from "../../redux/actions/user.action";
import TopBarLogo from "../statics/TopBarLogo";
import { getLeadById } from "../../services/lead.services";
import { User } from "../../redux/actions/user.action";
import { mapStyles } from "../../assets/map_styles";

//Icons
import { IoReturnUpBack } from "react-icons/io5";

//tracking
import { Tracking } from "../../services/tracking.service";

//Internationalization
import { useTranslation } from "react-i18next";
import { LanguagesType } from "../../i18n/translate.dto";
import { setLanguage } from "../../redux/actions/language.actions";
import { OpticDTO } from "../../services/DTOs_news/optics/optic.DTO";
import { setCountry } from "../../redux/actions/country.action";
import Footer from "../statics/Footer";

const libraries: any = ["places"];

const Ubication = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  let { idLead } = useParams();
  const [languageDefault, setLanguageDefault] = useState(
    navigator.language.slice(0, 2)
  );
  const [countryDefault, setCountryDefault] = useState(
    navigator.language.slice(3, 5)
  );

  //Load the redux state
  const reduxState = useSelector((state: any) => state);

  //Prepare the states for the component
  const [optics, setOptics] = useState<OpticDTO[]>([]);
  const [isLoadedPage, setIsLoaded] = useState(false);

  const [clickedOptic, setClickedOptic] = useState<OpticDTO>();

  const [isMapMode, setIsMapMode] = useState(false);
  const [search_cords, set_search_cords] = useState<{
    lat: number | null;
    lng: number | null;
  }>({
    lat: null,
    lng: null,
  });

  //responsive
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const isBreakDobleButton = useMediaQuery({
    query: "(max-width: 326px)",
  });

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAjBNf0HTsOuINudKthKmKQpumV3NRMjuw",
    libraries: libraries,
  });

  const nearestOpticRef = React.useRef<HTMLDivElement>(null);

  const logoRef = React.useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      if (logoRef.current) {
        window.scrollTo({
          top: logoRef.current.offsetTop,
        });
      }
    }, 100);
    return () => clearTimeout(timer);
  }, [logoRef, isLoadedPage]);

  useEffect(() => {
    //Check if the url has utms
    if (!searchParams) return;

    //Loop through the utms

    let modified = false;
    searchParams.forEach((value, key: string) => {
      //Check if the key is a utm
      if (!reduxState.user) reduxState.user = {};
      if (!reduxState.user?.utms) {
        reduxState.user.utms = {};
      }

      reduxState.user.utms[key] = value;
      dispatch(setUser({ ...reduxState.user, utms: reduxState.user.utms }));
    });
  }, [searchParams]);

  //Add auto complete to the search bar
  useEffect(() => {
    if (search_cords.lat !== null && search_cords.lng !== null) {
      getOpticaByProximity(search_cords.lat, search_cords.lng)
        .then(async (res: any) => {
          //Set only the first 5 optics
          await setOptics(res.data.slice(0, 5));
          await setIsLoaded(true);
        })
        .catch((e) => {
          console.log("ERROR", e);
        });
    }
  }, [isBreakDobleButton, search_cords, search_cords.lat, search_cords.lng]);

  useEffect(() => {
    if (idLead && idLead !== null && reduxState.idLead !== idLead) {
      // console.log('idLead', idLead);
      dispatch(setIdLead(idLead));

      //Get the Info of the ID
      getLeadById(idLead)
        .then(async (res) => {
          setIsLoaded(false);
          // console.log("res", res.data);
          const userOBJ: User = {
            ...reduxState.user,
            id: res.data?.idCustomer,
            firstName: res.data?.firstName,
            lastName: res.data?.lastName,
            phoneNumber: res.data?.phoneNumber,
            email: res.data?.email,
            postCode: res.data?.postCode,
            searchedAddress: res.data?.postCode,
            cords: {
              lat: res.data?.cords.lat,
              lng: res.data?.cords.lng,
            },
          };
          dispatch(setUser(userOBJ));
          set_search_cords({
            lat: res.data?.cords.lat,
            lng: res.data?.cords.lng,
          });
          setIsLoaded(true);
        })
        .catch((e) => {
          console.log("Error loading user data: ", e);
        });
    }
  }, [dispatch, idLead, reduxState]);

  //Update the cords of the search when user has cords saved
  useEffect(() => {
    if (reduxState.user?.cords) {
      set_search_cords({
        lat: reduxState.user.cords.lat,
        lng: reduxState.user.cords.lng,
      });
    }
  }, [reduxState, reduxState.user?.cords]);

  //Tracking
  useEffect(() => {
    Tracking.init();
  }, []);

  //Once the app has loaded, detect if the url has the parameter lang and change the language of the app to the one specified.
  useEffect(() => {
    if (searchParams.has("lang")) {
      switch (searchParams.get("lang")) {
        case "es":
          dispatch(setLanguage("es"));
          setSearchParams({ lang: "es" });
          dispatch(setCountry("es"));
          // Spain
          set_search_cords({
            lat: 40.4165,
            lng: -3.70256,
          });
          break;
        case "pt":
          dispatch(setLanguage("pt"));
          setSearchParams({ lang: "pt" });
          dispatch(setCountry("pt"));
          // Portugal
          set_search_cords({
            lat: 38.7222524,
            lng: -9.1393366,
          });
          break;
        case "uk":
        case "gb":
          dispatch(setLanguage("en"));
          setSearchParams({ lang: "uk" });
          dispatch(setCountry("uk"));
          // United Kingdom
          set_search_cords({
            lat: 51.509865,
            lng: -0.118092,
          });
          break;
        case "it":
          // Italy
          set_search_cords({
            lat: 41.89550600834823,
            lng: 12.481752909457265,
          });
          dispatch(setLanguage("it"));
          dispatch(setCountry("it"));
          setSearchParams({ lang: "it" });
          break;
        case "fr":
          // fr
          set_search_cords({
            lat: 48.858114721672244,
            lng: 2.3446966548200834,
          });
          dispatch(setLanguage("fr"));
          dispatch(setCountry("fr"));
          setSearchParams({ lang: "fr" });
          break;
        default:
          dispatch(setLanguage("es"));
          dispatch(setCountry("es"));
          setSearchParams({ lang: "es" });
          // Spain
          set_search_cords({
            lat: 40.4165,
            lng: -3.70256,
          });
          break;
      }
    }

    // Param country
    if (searchParams.has("country")) {
      switch (searchParams.get("country")) {
        case "es":
          dispatch(setLanguage("es"));
          dispatch(setCountry("es"));
          setSearchParams({
            country: "es",
            utm_source: "website",
            utm_medium: "header",
            utm_campaign: "cita_online",
          });
          // Spain
          set_search_cords({
            lat: 40.4165,
            lng: -3.70256,
          });
          break;
        case "pt":
          dispatch(setLanguage("pt"));
          dispatch(setCountry("pt"));
          setSearchParams({ country: "pt" });
          // Portugal
          set_search_cords({
            lat: 38.7222524,
            lng: -9.1393366,
          });
          break;
        case "uk":
        case "gb":
          dispatch(setLanguage("en"));
          dispatch(setCountry("uk"));
          setSearchParams({ country: "uk" });
          // United Kingdom
          set_search_cords({
            lat: 51.509865,
            lng: -0.118092,
          });
          break;
        case "au":
          dispatch(setLanguage("en"));
          dispatch(setCountry("au"));
          setSearchParams({ country: "au" });
          // Australia
          set_search_cords({
            lat: -33.861080507043376,
            lng: 151.10741772731254,
          });
          break;
        case "it":
          // Italy
          set_search_cords({
            lat: 41.89550600834823,
            lng: 12.481752909457265,
          });
          dispatch(setLanguage("it"));
          dispatch(setCountry("it"));
          setSearchParams({ country: "it" });
          break;
        case "fr":
          // France
          set_search_cords({
            lat: 48.858114721672244,
            lng: 2.3446966548200834,
          });
          dispatch(setLanguage("fr"));
          dispatch(setCountry("fr"));
          setSearchParams({ country: "fr" });
          break;
        default:
          set_search_cords({
            lat: 40.4165,
            lng: -3.70256,
          });
          dispatch(setLanguage("es"));
          dispatch(setCountry("es"));
          setSearchParams({
            country: "es",
            utm_source: "website",
            utm_medium: "header",
            utm_campaign: "cita_online",
          });
          break;
      }
    }
  }, [searchParams]);

  //If country change
  useEffect(() => {
    if (!searchParams.has("lang") || !searchParams.has("country"))
      switch (
        reduxState.country === null
          ? countryDefault.toLocaleLowerCase() === null
            ? countryDefault.toLocaleLowerCase()
            : languageDefault.toLocaleLowerCase()
          : reduxState.country
      ) {
        case "es":
          set_search_cords({
            lat: 40.4165,
            lng: -3.70256,
          });
          dispatch(setLanguage("es"));
          break;
        case "pt":
          set_search_cords({
            lat: 38.7222524,
            lng: -9.1393366,
          });
          dispatch(setLanguage("pt"));
          break;
        case "uk":
        case "gb":
        case "en":
          set_search_cords({
            lat: 51.509865,
            lng: -0.118092,
          });
          dispatch(setLanguage("en"));
          break;
        case "au":
          set_search_cords({
            lat: -33.861080507043376,
            lng: 151.10741772731254,
          });
          break;
        case "it":
          set_search_cords({
            lat: 41.89550600834823,
            lng: 12.481752909457265,
          });
          dispatch(setLanguage("it"));
          break;
        case "fr":
          // France
          set_search_cords({
            lat: 48.858114721672244,
            lng: 2.3446966548200834,
          });
          dispatch(setLanguage("fr"));
          break;
        default:
          set_search_cords({
            lat: 40.4165,
            lng: -3.70256,
          });
          dispatch(setLanguage("es"));
          break;
      }
  }, [reduxState.language, reduxState.country]);

  //Google Maps
  const [map, setMap] = React.useState(null);
  const onLoad = React.useCallback(function callback(map: any) {
    // new window.google.maps.LatLngBounds(search_cords);
    // //map.fitBounds(bounds);
    setMap(map);
  }, []);
  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const handleLocationChange = (lat: number, lng: number) => {
    set_search_cords({
      lat: lat,
      lng: lng,
    });

    if (nearestOpticRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: nearestOpticRef.current.offsetTop,
      });
    }
    // getOpticaByProximity(lat, lng)
    //     .then(async (res: any) => {
    //         // console.log('RES', res.data);
    //         await setOptics(res.data);
    //         await setIsLoaded(true);
    //     })
    //     .catch(e => {
    //         console.log('ERROR', e);
    //     })
  };

  useEffect(() => {
    if (nearestOpticRef.current) {
      //@ts-ignore
      window.scrollTo({
        behavior: "smooth",
        top: nearestOpticRef.current.offsetTop,
      });
    }
  }, [optics]);

  // const styleDobleButtonIsBreak = () => {
  //     if (isBreakDobleButton) {
  //         return {
  //             dobleButtonLeft: { ...style.dobleButtonLeft, borderRadius: '2em' },
  //             dobleButtonRight: { ...style.dobleButtonRight, borderRadius: '2em' }
  //         }
  //     } else {
  //         return {
  //             dobleButtonLeft: { ...style.dobleButtonLeft },
  //             dobleButtonRight: { ...style.dobleButtonRight }
  //         }
  //     }
  // }

  return (
    <Container>
      {reduxState.optic &&
      reduxState.schedule &&
      reduxState.user &&
      reduxState.appointment &&
      !reduxState.isEditMode ? (
        <Navigate to="/resume" />
      ) : (
        isLoadedPage && (
          <Container
            className="w-100 mw-100"
            style={
              isDesktopOrLaptop
                ? { maxHeight: "100vh", width: "100%", overflow: "hidden" }
                : { width: "100%" }
            }
          >
            <div ref={logoRef}>
              <TopBarLogo />
            </div>
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1em",
              }}
            >
              {/* Hidden col on PC  */}
              {isTabletOrMobile && (
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style={{
                    margin: "auto",
                  }}
                >
                  <Row className="mb-4">
                    <Col
                      xs="12"
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                      style={{ zIndex: 1 }}
                    >
                      <PutPostCode locationCallback={handleLocationChange} />
                    </Col>
                  </Row>
                  <Row>
                    {isMapMode && (
                      <Row
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        className="mb-2 d-flex justify-content-center mb-4"
                        style={{ width: "85vw", margin: "auto", padding: 0 }}
                      >
                        <Col className="d-flex justify-content-center align-items-center">
                          <Button
                            variant="contained"
                            color="primary"
                            style={{
                              ...style.button,
                              backgroundColor: "#EC6245",
                              color: "white",
                            }}
                            onClick={() => {
                              setIsMapMode(false);
                            }}
                          >
                            <span className="text-center">
                              {" "}
                              <IoReturnUpBack />
                              {t(LanguagesType.Go_to_the_list)}
                            </span>
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Row>
                </Col>
              )}
              {(isDesktopOrLaptop || !isMapMode) && (
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="12"
                  xl="6"
                  style={{ zIndex: 1 }}
                >
                  {/* Hiden when is for mobile */}
                  {!isTabletOrMobile && (
                    <>
                      <Row className="" style={{ maxHeight: "21vh" }}>
                        <Col
                          xs="12"
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                          style={{ zIndex: 1 }}
                        >
                          <PutPostCode
                            locationCallback={handleLocationChange}
                          />
                        </Col>
                      </Row>
                      <Row style={{ height: "6vh", maxHeight: "6vh" }} />
                    </>
                  )}
                  <div
                    style={
                      isDesktopOrLaptop
                        ? { maxHeight: "57vh", overflowX: "hidden" }
                        : {}
                    }
                  >
                    <div>
                      <Row>
                        <span
                          style={{
                            fontSize: "1.0em",
                            fontWeight: "bold",
                            color: "black",
                            width: "100%",
                            height: "6vh",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {reduxState.user?.cords?.lat
                            ? t(LanguagesType.This_is_your_optics)
                            : t(LanguagesType.Description_We_Need_Ubication2)}
                        </span>
                      </Row>
                    </div>

                    {optics.length !== 0 ? (
                      <div
                        style={
                          reduxState.user?.cords?.lat === undefined
                            ? style.disabledDiv
                            : {}
                        }
                      >
                        {/* <div> */}
                        {(reduxState.user?.cords?.lat || true) &&
                          optics.map((optic: OpticDTO, index: number) => {
                            const isSelected =
                              reduxState.optic?.id === optic.id;
                            const nearest =
                              index === 0 && reduxState.user?.cords?.lat;

                            if (nearest) {
                              return (
                                <div key={index} ref={nearestOpticRef}>
                                  <CardOptica
                                    isNearest={nearest}
                                    optic={optic}
                                    isSelected={isSelected}
                                    setClickedOptic={setClickedOptic}
                                    hideDistance={true}
                                    setIsMapMode={() => {
                                      if (isTabletOrMobile) {
                                        navigate("/map", {
                                          state: {
                                            optics: optics,
                                            selectedOptic: optic,
                                          },
                                        });
                                      } else {
                                        setIsMapMode(true);
                                      }
                                    }}
                                  />
                                </div>
                              );
                            }
                            return (
                              <div key={index}>
                                <CardOptica
                                  isNearest={nearest}
                                  optic={optic}
                                  isSelected={isSelected}
                                  setClickedOptic={setClickedOptic}
                                  hideDistance={true}
                                  setIsMapMode={() => {
                                    if (isTabletOrMobile) {
                                      navigate("/map", {
                                        state: {
                                          optics: optics,
                                          selectedOptic: optic,
                                        },
                                      });
                                    } else {
                                      setIsMapMode(true);
                                    }
                                  }}
                                />
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <Row>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                          <h3 className="mt-3 mb-5">
                            {t(LanguagesType.Not_Found_Optics)}
                          </h3>
                          {/* TODO: Añadir imagen*/}
                          <p className="mt-5">
                            {t(LanguagesType.Form_to_contact)}
                          </p>
                          <a
                            className="mt-2 mb-5"
                            style={style.button}
                            href="https://api.whatsapp.com/send/?phone=34689399720&text=%C2%A1Hola%21+Estoy+interesad%40+en+recibir+m%C3%A1s+informaci%C3%B3n+sobre+las+FLiPO.+&type=phone_number&app_absent=0&utm_source=contacto&utm_medium=button&utm_campaign=whatsapp"
                          >
                            WhatsApp
                          </a>
                        </div>
                      </Row>
                    )}
                  </div>
                </Col>
              )}
              {/* Map section. Not rendered by default on mobile to save resources and avoid the very slow loading of the map. */}
              {(isDesktopOrLaptop || isMapMode) && isLoaded && (
                <Col xs="12" sm="12" md="12" lg="12" xl="6">
                  <div className="w-100" style={{}}>
                    {search_cords.lat !== null && search_cords.lng !== null && (
                      <GoogleMap
                        mapContainerStyle={{
                          height: "100%",
                          minHeight: "80vh",
                          width: "100%",
                          borderRadius: "5px",
                        }}
                        onClick={() => setClickedOptic(undefined)}
                        zoom={12}
                        center={{
                          lat: search_cords.lat,
                          lng: search_cords.lng,
                        }}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        options={{
                          fullscreenControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          styles: mapStyles,
                        }}
                      >
                        {optics.length !== 0 &&
                          optics.map((optic: OpticDTO, index: number) => {
                            return (
                              <Marker
                                key={index}
                                position={{
                                  lat: optic.latitude,
                                  lng: optic.longitude,
                                }}
                                title={optic.name}
                                icon={{
                                  url: "/pin_mapa.png",
                                  scaledSize: new window.google.maps.Size(
                                    40,
                                    50
                                  ),
                                  scale: 30,
                                }}
                                onClick={() => {
                                  // console.log("Clicked marker of optic: ", optic.name);
                                  setClickedOptic(optic);
                                }}
                              />
                            );
                          })}

                        {search_cords && (
                          <Marker
                            position={{
                              lat: search_cords.lat,
                              lng: search_cords.lng,
                            }}
                            // Add a user possition marker with responsive size
                            icon={{
                              path: window.google.maps.SymbolPath.CIRCLE,
                              scaledSize: new window.google.maps.Size(10, 10),
                              scale: 10,
                              //Google maps blue color
                              fillColor: "#4285F4",
                              fillOpacity: 1,
                              strokeWeight: 0,
                            }}
                          />
                        )}
                        {clickedOptic && (
                          <InfoWindow
                            position={{
                              lat: clickedOptic.latitude,
                              lng: clickedOptic.longitude,
                            }}
                            onCloseClick={() => setClickedOptic(undefined)}
                          >
                            <>
                              <div>
                                {clickedOptic.distanceFromCords && (
                                  <span>
                                    {Math.round(
                                      clickedOptic.distanceFromCords * 10
                                    ) / 10}
                                    {t(LanguagesType.Distance)}
                                  </span>
                                )}
                                <h4>{clickedOptic.name}</h4>
                                <p>{clickedOptic.direction}</p>
                              </div>
                              <p
                                color="primary"
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  cursor: "pointer",
                                  color: "#EC6245",
                                  fontWeight: "bold",
                                  fontSize: "1.2em",
                                  marginBottom: ".1em",
                                }}
                                onClick={() => {
                                  // console.log("Clicked button of optic: ", clickedOptic.name);
                                  dispatch(setOptica({ ...clickedOptic })); //Pass a copy of the object to avoid problems with the state
                                  navigate("/horarios");
                                }}
                              >
                                {t(LanguagesType.Ask_date)}
                              </p>
                            </>
                          </InfoWindow>
                        )}
                      </GoogleMap>
                    )}
                    {/* </LoadScript> */}
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        )
      )}
      {isLoadedPage && <Footer />}
    </Container>
  );
};

const style = {
  button: {
    borderRadius: "1em",
    padding: "0.5em 1em",
    textDecoration: "none",
    border: "1px solid #EC6245",
    minHeight: "2.5em",
    color: "white",
    //width: '10em',
    cursor: "pointer",
    backgroundColor: "#EC6245",
    fontWeight: "bold",
  },
  dobleButtonLeft: {
    backgroundColor: "white",
    borderTopLeftRadius: "2em",
    borderBottomLeftRadius: "2em",
    overflow: "hidden",
    minHeight: "2.4em",
    fontWeight: "500",
    width: "auto",
    border: "2px solid #EC6245",
    cursor: "pointer",
  },
  dobleButtonRight: {
    backgroundColor: "white",
    borderTopRightRadius: "2em",
    borderBottomRightRadius: "2em",
    overflow: "hidden",
    minHeight: "2.4em",
    fontWeight: "500",
    width: "auto",
    border: "2px solid #EC6245",
    cursor: "pointer",
  },
  disabledDiv: {
    opacity: "0.3",
    pointerEvents: "none",
  } as React.CSSProperties,
};

export default Ubication;
