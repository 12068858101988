import {
    actionsOptica as actions
} from '../actions/types';

const opticaReducer = (state = null, action: any) => {
    switch (action.type) {
        case actions.SET_OPTICA:
            return action.payload;
        case actions.REMOVE_OPTICA:
            return null;
        default:
            return state;
    }
}

export default opticaReducer;