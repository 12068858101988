import { url } from "inspector";

export const URL_API = process.env.REACT_APP_API_URL || "http://localhost:3005";

if (URL_API === "http://localhost:3005") {
  console.warn(
    "%cDevelopment mode FLiPO",
    "background: linear-gradient(to right, #ff7e5f, #feb47b); color: white; padding: 2px 10px; border-radius: 5px;",
    URL_API
  );
}

// ! TEST
export const isDevelopment = false;
