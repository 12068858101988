import {
    actionsEditMode as actions
} from "../actions/types";

const editModeReducer = (state = false, action: any) => {
    switch (action.type) {
        case actions.SET_IS_EDIT_MODE:
            return action.payload;
        default:
            return state;
    }
}

export default editModeReducer;