import {
    actionsAppointment as actions
} from "./types";

export const setAppointment = (appointment: any) => {
    return {
        type: actions.SET_APPOINTMENT,
        payload: appointment,
    };
};

export const removeAppointment = () => {
    return {
        type: actions.REMOVE_APPOINTMENT
    };
};