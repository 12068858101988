/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import { getDM_HM } from "../../utils/functions";
import { Col, Container, Row } from "reactstrap";
import { setIsEditMode } from "../../redux/actions/editMode.action";
import { LanguagesType } from "../../i18n/translate.dto";
import { useTranslation } from "react-i18next";
import { BsCheckCircle, BsPencilSquare } from "react-icons/bs";
import { getAppointment } from "../../services/appointment.services";
import { OpticDTO } from "../../services/DTOs_news/optics/optic.DTO";
import { getOptica } from "../../services/optic.services";

import { removeOptica, setOptica } from "../../redux/actions/optica.action";
import { removeHorario, setHorario } from "../../redux/actions/horario.action";
import { removeUser, setUser } from "../../redux/actions/user.action";
import { removeIdLead } from "../../redux/actions/lead.action";
import {
  removeAppointment,
  setAppointment,
} from "../../redux/actions/appointment.action";
import Footer from "../statics/Footer";

const Resume = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { idAppointment } = useParams();

  const { t } = useTranslation();

  const [isLoaded, setIsLoaded] = useState(false);
  const reduxState = useSelector((state: any) => state);
  // const [reduxState, setReduxState] = useState(useSelector((state: any) => state))
  const [dayToShow, setDayToShow] = useState<any>();
  const [completedDay, setCompletedDay] = useState<any>();

  // const state = useSelector((state: any) => state)

  useEffect(() => {
    // If have an appointment, get the appointment and the optic
    if (idAppointment !== undefined) {
      getAppointment(idAppointment)
        .then((appointment) => {
          getOptica(appointment.idOptic)
            .then((_optic: any) => {
              const optic: OpticDTO = _optic.data;
              // Set the redux state
              dispatch(removeOptica());
              dispatch(removeHorario());
              dispatch(removeUser());
              dispatch(removeIdLead());
              dispatch(removeAppointment());

              dispatch(setOptica(optic));
              dispatch(setAppointment(appointment));
              dispatch(setHorario(+appointment.appointmentDate));
              dispatch(
                setUser({
                  id: appointment.idCustomer ? appointment.idCustomer : "",
                  firstName: appointment.customer?.firstName ?? "",
                  lastName: appointment.customer?.lastName ?? "",
                  email: appointment.customer?.email ?? "",
                  phoneNumber: appointment.customer?.phoneNumber ?? "",
                  postCode: appointment.customer?.postCode ?? "",
                })
              );

              const dayToShow = getDM_HM(appointment.appointmentDate);

              setDayToShow(dayToShow);

              let printable_day = new Date(
                appointment.appointmentDate
              ).toLocaleDateString(reduxState.language, {
                weekday: "long",
                day: "numeric",
                month: "long",
                hour: "numeric",
                minute: "numeric",
              });

              printable_day =
                printable_day.charAt(0).toUpperCase() + printable_day.slice(1);
              printable_day =
                printable_day.replace(",", "").replace(",", " -") + "h";

              setCompletedDay(printable_day);
              setIsLoaded(true);
            })
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      const dayToShow = getDM_HM(reduxState.schedule);
      setDayToShow(dayToShow);
      let printable_day = new Date(reduxState.schedule).toLocaleDateString(
        reduxState.language,
        {
          weekday: "long",
          day: "numeric",
          month: "long",
          hour: "numeric",
          minute: "numeric",
        }
      );

      printable_day =
        printable_day.charAt(0).toUpperCase() + printable_day.slice(1);
      printable_day = printable_day.replace(",", "").replace(",", " -") + "h";
      setCompletedDay(printable_day);
      setIsLoaded(true);
    }
  }, []);

  const goToInicio = () => {
    localStorage.removeItem("persist:root");
    window.location.replace("https://flipoeyewear.com/");
  };

  return (
    <div>
      {isLoaded &&
        (reduxState.optic !== null &&
        reduxState.schedule !== null &&
        reduxState.user !== null ? (
          <Container>
            <Col className="d-flex flex-column justify-content-center align-items-center text-center mt-5">
              <h2 className="mb-4">
                {t(LanguagesType.Greetings)} {reduxState.user.firstName}!
              </h2>
              <h4 className="mb-4 mt-2">
                {/* TODO: Take care with sintaxis of differents languages */}
                {reduxState.optic.accountManager} {t(LanguagesType.Of)}{" "}
                <span style={style.highlight}>{reduxState.optic.name}</span>{" "}
                {t(LanguagesType.Await_you)}
                <span style={style.highlight}>{" " + dayToShow.day + " "}</span>
                {t(LanguagesType.At)}{" "}
                <span style={style.highlight}>{" " + dayToShow.hour}</span>
              </h4>
              <div className="mb-4 mt-2">
                <p className="mb-4">
                  {t(LanguagesType.If_put)}{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {t(LanguagesType.No_24h)}
                  </span>{" "}
                  {t(LanguagesType.Pre_exam)}
                </p>
                <p style={{ ...style.mb1, fontWeight: "bold" }}>
                  {t(LanguagesType.Is_easy)}
                </p>
                <p style={style.mb1}>{reduxState.optic.name}</p>
                <p>{reduxState.optic.direction}</p>
              </div>
              {/* FOTO */}
              <div className="mb-4">
                <p style={{ fontWeight: "bold" }}>
                  {t(LanguagesType.Benefit_appointment_title)}
                </p>
                <p style={style.mb2}>
                  <BsCheckCircle
                    style={{
                      color: "#EC6245",
                      marginBottom: "0.3em",
                      marginRight: "0.2em",
                    }}
                  />{" "}
                  {t(LanguagesType.Benefit_appointment_1)}
                </p>
                <p style={style.mb2}>
                  <BsCheckCircle
                    style={{
                      color: "#EC6245",
                      marginBottom: "0.3em",
                      marginRight: "0.2em",
                    }}
                  />{" "}
                  {t(LanguagesType.Benefit_appointment_2)}
                </p>
                <p style={style.mb2}>
                  <BsCheckCircle
                    style={{
                      color: "#EC6245",
                      marginBottom: "0.3em",
                      marginRight: "0.2em",
                    }}
                  />{" "}
                  {t(LanguagesType.Benefit_appointment_3)}
                </p>
                <p style={style.mb2}>
                  <BsCheckCircle
                    style={{
                      color: "#EC6245",
                      marginBottom: "0.3em",
                      marginRight: "0.2em",
                    }}
                  />{" "}
                  {t(LanguagesType.Benefit_appointment_4)}
                </p>
              </div>

              <p style={style.highlight_bold}>{t(LanguagesType.Free)}</p>

              <div className="mt-2">
                <p style={style.mb2}>
                  {t(LanguagesType.Remember)}{" "}
                  <span style={style.highlight_bold}>
                    ({t(LanguagesType.Promotion)})
                  </span>
                </p>
                <p style={style.mb2}>
                  {t(LanguagesType.No_assist)}{" "}
                  <span style={style.highlight}>WhatsApp</span>{" "}
                  {t(LanguagesType.Or)}{" "}
                  <span style={style.highlight}>email</span>
                </p>
              </div>

              <h4 className="mt-5 mb-4">
                {t(LanguagesType.Until_the)} {completedDay}
              </h4>
              <Row className="mt-3" style={{ cursor: "pointer" }}>
                <span
                  style={{ color: "#8B8B8B" }}
                  onClick={() => {
                    dispatch(setIsEditMode(true));
                    navigate(`/confirmacion`);
                  }}
                >
                  {t(LanguagesType.Edit_my_data)} <BsPencilSquare />
                </span>
              </Row>

              <button
                className="mb-4 mt-3"
                onClick={goToInicio}
                style={style.button}
              >
                {t(LanguagesType.Start)}
              </button>
            </Col>
            {/* <Footer /> */}
          </Container>
        ) : (
          <>
            {idAppointment !== null &&
            idAppointment !== undefined &&
            idAppointment !== "" ? (
              <Spinner color="primary" type="grow">
                Cargando cita...
              </Spinner>
            ) : (
              <Navigate to="/" replace={true} />
            )}
          </>
        ))}
    </div>
  );
};

const style = {
  button: {
    borderRadius: "2em",
    border: "1px solid #EC6245",
    minHeight: "2.5em",
    color: "white",
    width: "10em",
    cursor: "pointer",
    backgroundColor: "#EC6245",
    fontWeight: "bold",
  },
  highlight: {
    color: "#EC6245",
  },
  highlight_bold: {
    color: "#EC6245",
    fontWeight: "bold",
  },
  mb1: {
    margin: 0,
    marginBottom: "-0.09em",
  },
  mb2: {
    margin: 0,
    marginBottom: ".5em",
  },
  edit: {
    color: "#8B8B8B",
    fontWeight: "400",
    textAlign: "center" as "center",
    cursor: "pointer",
  },
};

export default Resume;
