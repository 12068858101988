import {
    actionsHorario as actions
} from '../actions/types';

const horarioReducer = (state = null, action: any) => {
    switch (action.type) {
        case actions.SET_HORARIO:
            return action.payload;
        case actions.REMOVE_HORARIO:
            return null;
        default:
            return state;
    }
}

export default horarioReducer;