// Portuguese Spanish translation file for the i18n module

import { TranslateDto } from "../translate.dto";

export const pt: TranslateDto = {
  // Not found Page
  Not_found_page: "404: Página Não Encontrada",
  //Global
  Go_back: "Voltar ao início",
  // Topbar
  Slogan: "Os únicos óculos 100% personalizáveis",
  // PutPostCode
  Error_PERMISSION_DENIED: "O usuário negou permissão para geolocalização.",
  Error_POSITION_UNAVAILABLE:
    "As informações de localização não estão disponíveis.",
  Error_TIMEOUT: "O tempo de solicitação expirou.",
  Error_UNKNOWN_ERROR: "Um erro desconhecido ocorreu.",
  Error_default: "Um erro desconhecido ocorreu.",
  Search_your_optic: "Encontre a óptica mais próxima",
  Description_for_search_optic:
    "Introduza a sua morada e nós procuramos as ópticas mais perto de si 👇",
  Alert: "Parece que há algo errado",
  Placeholder_search_optic: "Escreva a sua morada ou CP",
  Can_I_Have_Your_Ubication: "Posso aceder à sua localização?",
  Description_We_Need_Ubication:
    "Para encontrar a óptica mais próxima de si, deve permitir-nos aceder à sua localização",
  Confirm: "Sim, claro!",
  Denaied: "Não, obrigado",
  Cant_Accesses_Your_Ubication:
    "Desculpe, não consigo aceder à sua localização",
  Description_Cant_Accesses_Your_Ubication:
    "Parece que você negou o acesso à sua localização. Para encontrar a óptica mais próxima de si, deverá escrever a sua morada ou código postal no campo acima",
  Accept: "Aceitar",
  Search_GPS: "Procurando GPS...",
  Use_My_Location: "Usar a minha localização atual",
  No_Selection_Location: "Você não selecionou nenhum endereço",
  Search: "Procurar",
  Ofert1: "Ganhe 30% de desconto + exame visual gratuito, apenas marcando uma visita",
  // Ubication
  Go_to_the_list: "De volta à lista",
  This_is_your_optics: "Estas são as ópticas mais próximas:",
  Description_We_Need_Ubication2:
    "Escreva o seu endereço na barra de pesquisa acima para ver a óptica mais próxima",
  Not_Found_Optics: "Ops...! Não há ópticas perto de si",
  Form_to_contact: "Também pode entrar em contato connosco através",
  Distance: " Km de distância",
  Ask_date: "SOLICITE UMA DATA",
  // CardOptic
  Optic_nearest: "Esta é a sua óptica mais próxima",
  Of_distance: " de distância",
  See_map: "Ver mapa",
  // Schedule
  Ofert2: "Marque uma visita e ganhe 25% de desconto e um exame visual grátis!",
  Choose_optic: "Óptica escolhida:",
  Direction: "Direção:",
  Selected_day: "Selecione um dia:",
  Choose_hour: "Escolha um horário para ser atendido:",
  Load_more_days: "Carregar mais dias",
  No_search_schedules: "Ops...! Nenhuma data disponível",
  Change_optic: "Mudar óptica",
  See_more: "Ver mais",
  Try_another_week: "Você também pode tentar outra semana",
  // Hours Container
  No_schedule: "Sem datas restantes",
  // Client Data
  Form_title: "Preencha o seus dados para agendar horário",
  Receive_ofert: "Você receberá a sua oferta por e-mail e WhatsApp",
  Your_choose_optic: "A sua óptica escolhida:",
  Your_choose_date: "A sua data escolhida:",
  Name: "Nome",
  Subname: "Apelido",
  Phone: "Telefone",
  Email: "E-mail",
  Incorrect_field: "Campo errado *",
  Next: "Próximo",
  // Confirmation
  Error_create_appointment: "Ocorreu um erro ao criar a visita.",
  Error_update_appointment: "Ocorreu um erro ao atualizar a visita.",
  Edit_your_data: "Edite os seus dados para alterar a data",
  Confirm_for_reserve: "Confirme os seus dados para marcar a visita",
  changeMyData: "Mudar os meus dados",
  Change_my_optic: "Mudar a minha óptica",
  Change_my_date: "Mudar a minha data",
  Your_data: "Os seus dados de contacto:",
  Confirm_appointment: "Confirmar visita",
  Edit_my_data: "Editar os meus dados",
  // Resume
  Greetings: "Olá",
  Of: "de",
  Await_you: "no dia estará à sua espera",
  At: "Às",
  If_put: "Se usa lentes de contacto",
  No_24h: "não as coloque por 24 horas",
  Pre_exam: "pré-exame",
  Is_easy: "É muito fácil chegar a:",
  Benefit_appointment_title: "Durante a consulta:",
  Benefit_appointment_1: "Nós avaliamos gratuitamente",
  Benefit_appointment_2:
    "Pode experimentar uma ampla seleção de cores e acessórios",
  Benefit_appointment_3:
    "Irá ver qual dos nossos modelos mais se adapta ao seu rosto",
  Benefit_appointment_4:
    "Pode ver com o seu oftalmologista quais clipes precisa",
  Free: "Tudo de graça!",
  Remember:
    "Lembre-se que com um horário agendado, não terá que fazer fila, é também, estando presente terá acesso aos  ",
  Promotion: "promoção",
  No_assist:
    "Caso não possa comparecer ou tenha alguma dúvida, entre em contato connosco via",
  Or: "ou",
  Start: "Começar",
  Until_the: "Até o",
  // FAQs
  Question_1: "De que são feitos os óculos FLiPO?",
  Answer_1:
    "Todos os nossos produtos estão fabricados com um material pioneiro pela sua flexibilidade e resistência, conhecido como ULTEM. Para mais informação podes consultar a ficha técnica de cada modelo de óculos. 🤓🔛😎👍.",
  Question_2: "Como funcionam os clips e as hastes?",
  Answer_2:
    "Os clips e as hastes funcionam graças a um sistema magnético 🧲 que faz com que estes se possam aplicar de uma maneira fácil e segura sem o medo de que se vão soltar. 🤓🔛😎🙌",
  Question_3: "Que diferença há entre o clip funcional e o de desenho?",
  Answer_3:
    "O clip funcional 👓 reúne a ajuda visual das lentes graduadas e através das diferentes funcionalidades transforma os teus óculos, protege da luz azul, do sol e dos diferentes raios que possam afetar a tua vista 🤓. Enquanto que, o clip de desenho muda a aparência da parte frontal 😎da armação juntamento com a troca da haste, conseguindo mudar o estilo dos óculos por completo. 🤓🔛😎🙌",
  Question_4: "Quando estarão prontos os meus FLiPO monofocais?",
  Answer_4:
    "Os teus óculos estarão graduados em 7-10 dias laborais. E depois, é só disfrutar! 💥",
  Question_5: "Quando estarão prontos os meus FLiPO progressivos?",
  Answer_5:
    "Os teus óculos estarão graduados em 7-10 dias laborais. A espera vale a pena!⚡️",
};
