import { useState } from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript, // Changed to useLoadScript hook
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { Col } from "reactstrap";
import { mapStyles } from "../../assets/map_styles";

// selectedOptic.location

const Map = () => {
  const selectedOptic = useSelector((state: any) => state.optic);
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyAjBNf0HTsOuINudKthKmKQpumV3NRMjuw",
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Maps</div>;
  }

  return (
    <Col xs="12" sm="12" md="12" lg="12" xl="12">
      <div className="w-100">
        <GoogleMap
          mapContainerStyle={{
            height: "100%",
            minHeight: "100vh",
            width: "100%",
            borderRadius: "5px",
          }}
          //   onClick={() => setSelectedOptic(undefined)}
          zoom={17}
          center={{
            lat: selectedOptic.latitude,
            lng: selectedOptic.longitude,
          }}
          options={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: mapStyles,
          }}
          onClick={() => setInfoWindowOpen(false)}
        >
          {google && (
            <Marker
              position={{
                lat: selectedOptic.latitude,
                lng: selectedOptic.longitude,
              }}
              title={selectedOptic.name}
              icon={{
                url: "/pin_mapa.png",
                scaledSize: new google.maps.Size(40, 50),
                scale: 30,
              }}
              onClick={() => setInfoWindowOpen(true)}
            />
          )}
          {infoWindowOpen && window.google && (
            <InfoWindow
              position={{
                lat: selectedOptic.latitude,
                lng: selectedOptic.longitude,
              }}
              onCloseClick={() => setInfoWindowOpen(false)}
              options={{
                pixelOffset: new google.maps.Size(0, -40),
              }}
            >
              <>
                <div>
                  <h4>{selectedOptic.name}</h4>
                  <p>{selectedOptic.direction}</p>
                </div>
                {/* <p
                          color="primary"
                          style={{
                            width: "100%",
                            textAlign: "center",
                            cursor: "pointer",
                            color: "#EC6245",
                            fontWeight: "bold",
                            fontSize: "1.2em",
                            marginBottom: ".1em",
                          }}
                          onClick={() => {
                            // console.log("Clicked button of optic: ", selectedOptic.name);
                            dispatch(setOptica({ ...selectedOptic })); //Pass a copy of the object to avoid problems with the state
                            navigate("/horarios");
                          }}
                        >
                          {t(LanguagesType.Ask_date)}
                        </p> */}
              </>
            </InfoWindow>
          )}
        </GoogleMap>
      </div>
    </Col>
  );
};

export default Map;
