import { useDispatch, useSelector } from "react-redux";
import Map from "./Map";
import Resume from "./Resume";
import Footer from "../statics/Footer";

const ResumeWrapper = () => {
  const optic = useSelector((state: any) => state.optic);

  return (
    <div className="row">
      <div className="col-md-6">
        <Resume />
      </div>
      <div className="col-md-6">
        <Map />
      </div>
      <Footer />
    </div>
  );
};

export default ResumeWrapper;
