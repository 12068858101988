import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';

export class Tracking {

  public static init() {
    // Facebook Pixel
    ReactPixel.init('1102495797339947');
    ReactPixel.pageView();

    // Google Analytics
    ReactGA.initialize('G-P0HTMD0DF8');
    ReactGA.send({ hitType: 'pageview', page: (window.location.pathname + window.location.search) });
  }

  public static track(eventName: string, parameters: any) {
    // Facebook Pixel
    this.trackFB(eventName, parameters);
    this.trackGA(eventName);

  }

  public static trackFB(eventName: string, parameters: any) {
    // Facebook Pixel
    ReactPixel.track(eventName, parameters);

  }

  public static trackGA(eventName: string) {
    // Google Analytics
    ReactGA.event({
      category: 'Cita Online',
      action: eventName,
    });


  }

  public static trackBuscarOpticas() {
    this.trackFB('FindLocation', {});
    this.trackGA('FindLocation');
  }

  public static trackOpticaSelecionada(optic_id: string) {
    this.track('OpticaSelecionada', { optic_id });
  }

  public static trackHoraSeleccionada(optic_id: string) {
    this.track('hora_selecionada_cita_online', { optic_id });
  }

  public static trackCitaReservada(optic_id: string) {
    this.track('Schedule', { optic_id });
  }

}