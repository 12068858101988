import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Routes, Route } from "react-router-dom";

import loadable from "@loadable/component";

// Main components
import Ubication from "./components/pages/Ubication";
import NoMatch from "./components/pages/NoMatch";

// i18n
import { initi18n } from "./i18n/main_i18n";
import ResumeWrapper from "./components/pages/ResumeWrapper";

const Confirmation = loadable(
  () => import(/* webpackPrefetch: true */ "./components/pages/Confirmation")
);
const Schedule = loadable(
  () => import(/* webpackPrefetch: true */ "./components/pages/ScheduleV2")
);
const ClientData = loadable(
  () => import(/* webpackPrefetch: true */ "./components/pages/ClientData")
);
const Resume = loadable(
  () => import(/* webpackPrefetch: true */ "./components/pages/Resume")
);
const MapView = loadable(
  () => import(/* webpackPrefetch: true */ "./components/pages/MapView")
);

// init i18n
initi18n();

function App() {
  return (
    <Routes>
      <Route path="/:idLead?" element={<Ubication />} />
      <Route path="horarios" element={<Schedule />} />
      <Route path="form" element={<ClientData />} />
      <Route path="confirmacion" element={<Confirmation />} />
      <Route path="resume" element={<ResumeWrapper />} />
      <Route path="resume/:idAppointment?" element={<Resume />} />
      <Route path="appointment/:idAppointment?" element={<Resume />} />
      <Route path="map" element={<MapView />} />
      <Route path="/" element={<NoMatch />} />
    </Routes>
  );
}

export default App;
