// crea un footer en el que se incluya las políticas de privacidad y coookies, aviso legal y términos y condiciones
import { FC } from "react";
import { Row } from "reactstrap";
import "./Footer.css";

const Footer: FC = () => {
  return (
    <>
      <Row tag="footer" className="pt-1">
        <ul className="list">
          <li>
            <a href="https://flipoeyewear.com/policies/privacy-policy">
              Políticas de privacidad y cookies
            </a>
          </li>
          <li>
            <a href="https://flipoeyewear.com/policies/legal-notice">
              Aviso legal
            </a>
          </li>
          <li>
            <a href="https://flipoeyewear.com/policies/terms-of-service">
              Términos y condiciones
            </a>
          </li>
        </ul>
      </Row>
    </>
  );
};

export default Footer;
