import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row } from "reactstrap";
import { setLanguage as setLanguageRedux } from "../../redux/actions/language.actions";

import FlipoLogo from "./../../assets/FLIPO_Logo.svg";

const TopBarLogo = () => {
  const [, setLanguage] = useState<boolean>(false);
  const dispatch = useDispatch();

  const goBack = () => {
    window.history.back();
  };

  const reduxState = useSelector((state: any) => state);

  const changeLanguage = () => {
    if (reduxState.language === "es") {
      setLanguage(true);
      dispatch(setLanguageRedux("pt"));
    } else {
      setLanguage(false);
      dispatch(setLanguageRedux("es"));
    }
  };
  return (
    <Row style={{ height: "" }} tag="article">
      <div className="position-relative" style={{ height: "10rem" }}>
        <div
          className="position-absolute"
          style={{
            top: 50,
            left: 20,
          }}
        >
          {window.location.pathname !== "/" && (
            <button className="btn" onClick={goBack}>
              <span className="material-symbols-outlined fs-6 pt-1 m-0">
                arrow_back
              </span>
            </button>
          )}
        </div>
        <div className="position-absolute top-50 start-50 translate-middle">
          <img
            style={{ height: "8rem" }}
            src={FlipoLogo}
            alt="imagen del logo de FLIPO"
          />
        </div>
      </div>
      <div>
        {/* <button style={{backgroundColor: 'white', border: 0, cursor: 'pointer', fontWeight: '500'}} onClick={changeLanguage}>{reduxState.language}</button> */}
      </div>
      <div className="mb1"></div>
    </Row>
  );
};

export default TopBarLogo;
