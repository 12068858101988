import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LanguagesType } from "../../i18n/translate.dto";

const NoMatch = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const goToHome = () => {
        navigate("/");
    }

    return (
        <div>
            <h1>{t(LanguagesType.Not_found_page)}</h1>
            <br />
            <br />
            <button onClick={goToHome}>{t(LanguagesType.Go_back)}</button>
        </div>
    );
};

export default NoMatch;