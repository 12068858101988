// French translation file for the i18n module
import { TranslateDto } from "../translate.dto";

export const fr: TranslateDto = {
  // Not found Page
  Not_found_page: "404: Page non trouvée",
  //Global
  Go_back: "Retour à l'accueil",
  // Topbar
  Slogan: "Les seules lunettes 100% personnalisables",
  // PutPostCode
  Error_PERMISSION_DENIED:
    "L'utilisateur a refusé la permission de géolocalisation.",
  Error_POSITION_UNAVAILABLE:
    "Les informations de localisation ne sont pas disponibles.",
  Error_TIMEOUT: "Le délai de la demande a expiré.",
  Error_UNKNOWN_ERROR: "Une erreur inconnue s'est produite.",
  Error_default: "Une erreur inconnue s'est produite.",
  Search_your_optic: "Recherchez votre opticien le plus proche",
  Description_for_search_optic:
    "Entrez votre adresse et nous chercherons les opticiens les plus proches de vous 👇",
  Alert: "Il semble y avoir un problème",
  Placeholder_search_optic: "Entrez votre adresse ou code postal",
  Can_I_Have_Your_Ubication: "Puis-je avoir votre localisation?",
  Description_We_Need_Ubication:
    "Pour trouver l'opticien le plus proche de vous, vous devez me permettre d'accéder à votre localisation",
  Confirm: "Oui, bien sûr!",
  Denaied: "Non, merci",
  Cant_Accesses_Your_Ubication:
    "Désolé, je ne peux pas accéder à votre localisation",
  Description_Cant_Accesses_Your_Ubication:
    "Il semble que vous ayez refusé l'accès à votre localisation. Pour trouver l'opticien le plus proche de vous, vous devez entrer votre adresse ou code postal dans le champ ci-dessus",
  Accept: "D'accord",
  Search_GPS: "Recherche GPS...",
  Use_My_Location: "Utiliser ma localisation actuelle",
  No_Selection_Location: "Vous n'avez sélectionné aucune adresse",
  Search: "Rechercher",
  Ofert1:
    "Obtenez une réduction de 25% + un examen visuel gratuit simplement en prenant rendez-vous",
  // Ubication
  Go_to_the_list: "Retour à la liste",
  This_is_your_optics: "Voici vos opticiens les plus proches:",
  Description_We_Need_Ubication2:
    "Entrez votre adresse dans le champ de recherche ci-dessus pour voir votre opticien le plus proche",
  Not_Found_Optics: "Oups...! Il n'y a pas d'opticiens près de chez vous",
  Form_to_contact: "Vous pouvez également nous contacter par",
  Distance: " Km de distance",
  Ask_date: "PRENDRE RENDEZ-VOUS",
  // CardOptic
  Optic_nearest: "C'est votre magasin le plus proche",
  Of_distance: " de distance",
  See_map: "Voir la carte",
  // Schedule
  Ofert2:
    "Prenez rendez-vous et obtenez une réduction et un examen visuel gratuit!",
  Choose_optic: "Opticien choisi:",
  Direction: "Adresse:",
  Selected_day: "Sélectionnez un jour:",
  Choose_hour: "Choisissez une heure pour votre rendez-vous:",
  Load_more_days: "Charger plus de jours",
  No_search_schedules: "Oups...! Il n'y a pas de rendez-vous disponibles",
  Change_optic: "Changer d'opticien",
  See_more: "Voir plus",
  Try_another_week: "Vous pouvez également essayer une autre semaine",
  // Hours Container
  No_schedule: "Il n'y a plus de rendez-vous",
  // Client Data
  Form_title: "Remplissez vos informations pour réserver le rendez-vous",
  Receive_ofert: "Vous recevrez votre offre par email et WhatsApp",
  Your_choose_optic: "Votre opticien choisi:",
  Your_choose_date: "Votre date choisie:",
  Name: "Nom",
  Subname: "Prénom",
  Phone: "Téléphone",
  Email: "Email",
  Incorrect_field: "Champ incorrect *",
  Next: "Suivant",
  // Confirmation
  Error_create_appointment:
    "Une erreur s'est produite lors de la création du rendez-vous.",
  Error_update_appointment:
    "Une erreur s'est produite lors de la mise à jour du rendez-vous.",
  Edit_your_data: "Modifiez vos informations pour changer le rendez-vous",
  Confirm_for_reserve:
    "Confirmez vos informations pour réserver le rendez-vous",
  Change_my_optic: "Changer mon opticien",
  Change_my_date: "Changer ma date",
  changeMyData: "Changer mes informations",
  Your_data: "Vos informations de contact:",
  Confirm_appointment: "Confirmer le rendez-vous",
  Edit_my_data: "Modifier mes informations",
  Greetings: "Bonjour",
  Of: "de",
  Await_you: "vous attendra le jour",
  At: "à",
  If_put: "Si vous portez des lentilles",
  No_24h: "ne les portez pas pendant les 24h",
  Pre_exam: "précédant l'examen",
  Is_easy: "C'est très facile d'arriver à:",
  Benefit_appointment_title: "Pendant le rendez-vous:",
  Benefit_appointment_1: "Nous vérifions gratuitement",
  Benefit_appointment_2:
    "Vous pourrez essayer une large sélection de couleurs et d'accessoires",
  Benefit_appointment_3:
    "Vous verrez quel modèle de nos lunettes s'adapte le mieux à votre visage",
  Benefit_appointment_4:
    "Vous pourrez consulter votre opticien sur les clips dont vous avez besoin",
  Free: "Tout est gratuit!",
  Remember:
    "N'oubliez pas qu'avec votre rendez-vous réservé, vous n'aurez pas à faire la queue, et en plus, simplement en assistant, vous pourrez accéder à la",
  Promotion: "promotion",
  No_assist:
    "Si vous ne pouvez pas assister ou si vous avez des questions, vous pouvez nous contacter via",
  Or: "ou",
  Start: "Accueil",
  Until_the: "Jusqu'au",
  // FAQs
  Question_1: "De quoi sont faites les lunettes FLiPO?",
  Answer_1:
    "Tous nos produits sont fabriqués avec un matériau pionnier pour sa flexibilité et sa résistance, connu sous le nom d'ULTEM. Pour plus d'informations, vous pouvez consulter la fiche technique de chaque modèle de lunettes 🤓🔛😎👍",
  Question_2: "Comment fonctionnent les clips et les branches?",
  Answer_2:
    "Les clips et les branches fonctionnent grâce à un système magnétique 🧲 qui permet de les appliquer facilement et en toute sécurité sans craindre qu'ils ne se détachent. 🤓🔛😎🙌",
  Question_3:
    "Quelle est la différence entre le clip fonctionnel et le clip de design?",
  Answer_3:
    "Le clip fonctionnel 👓 combine l'aide visuelle des verres correcteurs, et grâce aux différentes fonctionnalités, transforme vos lunettes et protège de la lumière bleue, du soleil et des différents rayons qui peuvent affecter votre vue 🤓. En revanche, le clip de design change l'apparence de la face avant 😎 de la monture et, avec l'échange des branches, permet de changer complètement le style des lunettes.🤓🔛😎🙌",
  Question_4: "Quand mes FLiPO monofocales seront-elles prêtes?",
  Answer_4:
    "Vos lunettes seront prêtes en 7-10 jours ouvrables. Et ensuite, profitez-en!💥",
  Question_5: "Quand mes FLiPO progressives seront-elles prêtes?",
  Answer_5:
    "Vos lunettes seront prêtes en 7-10 jours ouvrables. L'attente en vaut la peine!⚡️",
};
