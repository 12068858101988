import { actionsHorario as actions } from "./types";

export const setHorario = (horario: any) => {
    return {
        type: actions.SET_HORARIO,
        payload: horario,
    };
};

export const removeHorario = () => {
    return {
        type: actions.REMOVE_HORARIO
    };
};